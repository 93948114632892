.navbar {
    background-color: #289c4a;
    background-image: linear-gradient(to bottom,#5cb85c 0,#419641 100%);
    box-shadow: none;
    border: 0;
}

#nav a {
    padding: 12px 25px;
    color: #FFF;
    font-size: 135%;
    font-weight: bold;
    text-decoration: none;
}

#nav .navbar {
    margin: 0;
    padding: 0;
}

#nav a:hover, #nav a.selected {
    color: #343;
    background-color: #f0fffd;
    text-decoration: none;
}
