#categories tr.race-0 {
    background-color: #dff0d8;
}

#categories tr.race-1 {
    background-color: #fcf8e3;
}

#categories tr.race-2 {
    background-color: #fae4e1;
}