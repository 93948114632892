body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



@media (max-width: 992px) {

  #root #app #nav div.smol {
    max-width: 100%;
  }

  #nav div.navbar-nav > a {
    margin: 0;
    font-size: 165%;
    text-indent: 0.4em;
  }
}


#app h1 {
  font-size: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

#app h2 {
  font-size: 1.8rem;
  margin-top: 0.7rem;
  margin-bottom: 1.3rem;
}


.navbar-nav {
  padding-bottom: 0;
}

button.navbar-toggler {
  background-color: #289c4a;
}

button.collapsed {

  background-color: #ddd;
}

.container .navbar {

  border: none;
}

.navbar-toggler {
  float: left;
  margin: 8px 0 8px 15px;
}

.center {
  text-align: center;
}
