#persons .thumbnail {
    display: block;
    padding: 12px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
}

#persons .thumbnail img.userImage {
    display: block;
    max-width: 200px;
    margin: 0 auto 25px;
    border: 3px solid #fff;
    border-radius: 100%;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);;
}