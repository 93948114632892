/* roboto-regular - latin */
/* roboto-regular - latin-ext_latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/roboto-v27-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./fonts/roboto-v27-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./fonts/roboto-v27-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./fonts/roboto-v27-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */ url('./fonts/roboto-v27-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./fonts/roboto-v27-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

#root {
    display: flex;
    flex-flow: column;
    height: 100%;
}

html, body {
    font-family: 'Roboto', serif;
    height: 100%;
    width: 100%;
    background-color: #f0fffd;
}

a {
    color: #1b6b32;
}

a:hover {
    color: #20803c;
    text-decoration: underline;
}

#app {
    display: flex;
    flex-flow: column;
    height: 100%;
}

#app .btn-success {
    background-color: #289c4a;
    background-image: linear-gradient(to bottom, #5cb85c 0, #419641 100%);
    box-shadow: none;
    border: 0;
}

#app .btn-big {
    width: 380px;
    font-size: 200%;
    margin-bottom: 1em;
    border: 3px #000 solid;
}

#app div.content {
    margin-top: 20px
}

#app form div {
    margin-bottom: 14px;
}
