.photo {
    margin: 0 0 1em;
    padding: 0;
    border: 4px #289c4a solid;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    max-width: 100%;
}

