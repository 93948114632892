#fees tr.fee-0 {
    background-color: #dff0d8;
}

#fees tr.fee-1 {
    background-color: #fcf8e3;
}

#fees tr.fee-2 {
    background-color: #fae4e1;
}